import React from "react"
import { graphql } from "gatsby"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import Carousel, { CarouselImages } from "Components/Carousel"
import SEO from "Components/core/SEO"

interface GriffinGameProps {
  data: CarouselImages
}

const GriffinGame: React.FC<GriffinGameProps & GatsbyProps> = ({
  location,
  data: { smallestImages, smallImages, mediumImages, largeImages },
}) => (
  <StandardLayout location={location}>
    <SEO title="Griffin Game" />
    <h1>Griffin Game</h1>
    <p>
      The final project I did in Flash was as part of a 3 man and we were to
      create a basic game that was similar to a tamagotchi pet. The angle my
      team too was to make something featuring a griffin as the pet, and have
      the player represent as a wizard, the wizard would then use magical spells
      to heal, clean and feed their pet griffin.
    </p>
    <Carousel
      smallestImages={smallestImages}
      smallImages={smallImages}
      mediumImages={mediumImages}
      largeImages={largeImages}
      altTexts={[
        "Screenshot of home page",
        "Screenshot of wizard name input",
        "Screenshot of Griffin name input",
        "Screenshot of Griffin poop",
        "Screenshot of Griffin Eating",
        "Screenshot of Griffin being healed",
        "Screenshot of Griffin poop being cleaned",
        "Screenshot of Griffin being washed",
      ]}
    />
  </StandardLayout>
)
export const query = graphql`
  query {
    smallestImages: allFile(
      filter: { relativePath: { glob: "flash-projects/griffin-trainer*" } }
    ) {
      edges {
        node {
          ...CarouselSmallestImage
        }
      }
    }
    smallImages: allFile(
      filter: { relativePath: { glob: "flash-projects/griffin-trainer*" } }
    ) {
      edges {
        node {
          ...CarouselSmallImage
        }
      }
    }
    mediumImages: allFile(
      filter: { relativePath: { glob: "flash-projects/griffin-trainer*" } }
    ) {
      edges {
        node {
          ...CarouselMediumImage
        }
      }
    }
    largeImages: allFile(
      filter: { relativePath: { glob: "flash-projects/griffin-trainer*" } }
    ) {
      edges {
        node {
          ...CarouselLargeImage
        }
      }
    }
  }
`
export default GriffinGame
